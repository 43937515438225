<template>
  <CContainer
    class="d-flex align-items-center min-vh-100 justify-content-center page404"
  >
    <div class="w-75 box-content d-flex flex-column px-3">
      <div class="clearfix">
        <div class="d-flex justify-content-center">
          <img
            class="img-maintenance"
            src="@/assets/img/maintenance.png"
            width="100%"
            height="100%"
          />
        </div>
        <div
          class="text-center font-weight-bold mb-4"
          style="font-size: 1.5rem"
        >
          <p>ただいまメンテナンス中です</p>
        </div>
        <div class="mb-5 mx-auto text-desc">
          <p>
            現在メンテナンスを行っております。ご迷惑をおかけして誠に申し訳ありません。
          </p>
          <p>お客様の通信状況を調査しつつ調整を行なっておりますので、</p>
          <p>予告なくメンテナンスの実施及び解除を行うことがございます。</p>
        </div>
        <div class="mb-4 mx-auto text-desc">
          <p>
            ご不便をおかけいたしますが、何卒ご理解いただきますようお願いいたします。
          </p>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
export default {
  name: "PageMaintenance",
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.text-desc {
  font-size: 1.2rem;
  width: 55%;
}
.img-maintenance {
  object-fit: contain;
  width: 55%;
  height: 100%;
}
.link-home-page:hover {
  color: #aaa !important;
}
.default-layout .page404 .box-content {
  width: 823.6px;
  margin: 10px auto;
  box-shadow: 5px 5px 10px #7681929c !important;
  border-radius: 20px !important;
}
@media screen and (max-width: 991px) {
  .default-layout .page404 .box-content {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  .text-desc {
    width: 75%;
  }
  .img-maintenance {
    width: 75%;
  }
}
@media (max-width: 576px) {
  .container {
    width: 100% !important;
    font-size: 16px !important;
  }
  .text-desc {
    width: 95%;
  }
  .img-maintenance {
    width: 95%;
  }
}
@media (min-width: 991px) {
  .container {
    width: 100% !important;
  }
}
</style>
